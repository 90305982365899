import {useEffect} from 'react';
import sal from 'sal.js';
import "sal.js/dist/sal.css";
export default function Wrapper({children, path, options}) {
    useEffect(() => {
        sal({rootMargin: '0% 50%',});
    }, [path]);

    return children;
}
